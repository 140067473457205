$(document).ready(function () {
  // ヘッダーのサブメニュー表示
  //マウスを乗せたら発動
  $('.pull-down').hover(function() {
    var element = $(this).children('.sub-menu');
    element.addClass('display-block');
  //ここにはマウスを離したときの動作を記述
  }, function() {
    $('.sub-menu').removeClass('display-block')
  });

  $("#contact_agreement").change(function () {
    // チェックが入っていたら有効化
    if ($(this).is(":checked")) {
      // ボタンを有効化
      $(".register-red-btn").prop("disabled", false);
    } else {
      // ボタンを無効化
      $(".register-red-btn").prop("disabled", true);
    }
  });

  $('#tour_agreement, #tour_privacy').on('change', function() {
    if ($('#tour_agreement').is(':checked') && $('#tour_privacy').is(':checked')) {
      $('.register-red-btn').prop('disabled', false);
    } else {
      $('.register-red-btn').prop('disabled', true);
    }
  });
});

$(function(){

//モーダルウィンドウを出現させるクリックイベント
$(".modal-open").click( function(){
  //キーボード操作などにより、オーバーレイが多重起動するのを防止する
  $( this ).blur() ;	//ボタンからフォーカスを外す
  if( $( "#modal-overlay" )[0] ) return false ;		//新しくモーダルウィンドウを起動しない (防止策1)
  //if($("#modal-overlay")[0]) $("#modal-overlay").remove() ;		//現在のモーダルウィンドウを削除して新しく起動する (防止策2)
  //オーバーレイを出現させる
  $( "body" ).append( '<div id="modal-overlay"></div>' ) ;
  $( "#modal-overlay" ).fadeIn( "slow" ) ;
  //コンテンツをセンタリングする
  centeringModalSyncer() ;
  //コンテンツをフェードインする
  $(this).next( ".modal-content" ).fadeIn( "slow" ) ;
  //[#modal-overlay]、または[#modal-close]をクリックしたら…
  $( "#modal-overlay, .modal-close" ).click( function(){
    //[.modal-content]と[#modal-overlay]をフェードアウトした後に…
    $(".modal-content").fadeOut( "slow" ) ;
    $("#modal-overlay").fadeOut("slow", function() {
      $("#modal-overlay").remove();
    });
  } ) ;
} ) ;

//リサイズされたら、センタリングをする関数[centeringModalSyncer()]を実行する
$( window ).resize( centeringModalSyncer ) ;

  //センタリングを実行する関数
  function centeringModalSyncer() {

    //画面(ウィンドウ)の幅、高さを取得
    var w = $( window ).width() ;
    var h = $( window ).height() ;

    // コンテンツ(.modal-content)の幅、高さを取得
    // jQueryのバージョンによっては、引数[{margin:true}]を指定した時、不具合を起こします。
//		var cw = $( ".modal-content" ).outerWidth( {margin:true} );
//		var ch = $( ".modal-content" ).outerHeight( {margin:true} );
    var cw = $( ".modal-content" ).outerWidth();
    var ch = $( ".modal-content" ).outerHeight();

    //センタリングを実行する
    $( ".modal-content" ).css( {"left": ((w - cw)/2) + "px","top": ((h - ch)/2) + "px"} ) ;

  }

} ) ;