$(document).ready(function () {
  var elem = document.querySelector("#slide01");
  if (elem !== null) { //swiper-containerがあれば…
    var mySwiper = new Swiper("#slide01", {
      loop: true, //ループさせる
      effect: "fade", //フェードのエフェクト
      fadeEffect: { // Add this
        crossFade: true,
      },
      autoplay: {
        delay: 4000, //４秒後に次の画像へ
        disableOnInteraction: false //ユーザー操作後に自動再生を再開する
      },
      speed: 2000, //２秒かけながら次の画像へ移動
      allowTouchMove: false, //マウスでのスワイプを禁止
    });
  }

  var elem = document.querySelector("#slide02");
  if (elem !== null) { //swiper-containerがあれば…
    var mySwiper = new Swiper("#slide02", {
      loop: true, //ループさせる
      effect: "fade", //フェードのエフェクト
      fadeEffect: { // Add this
        crossFade: true,
      },
      autoplay: {
        delay: 4000, //４秒後に次の画像へ
        disableOnInteraction: false //ユーザー操作後に自動再生を再開する
      },
      speed: 2000, //２秒かけながら次の画像へ移動
      allowTouchMove: false, //マウスでのスワイプを禁止
    });
  }
});
