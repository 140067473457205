$(document).ready(function () {
  function Search(){
    var str1="";
    var COUNTsiku1= document.siku1.elements.length;
    for( i=0; i<COUNTsiku1; i++ )  {
      if( document.siku1.elements[i].checked ) {
      if( str1 != "" ) str1=str1+",";
      str1=str1+document.siku1.elements[i].value;
      }
    }

    // var str2="";
    // var COUNTsiku2= document.siku2.elements.length;
    // for( i=0; i<COUNTsiku2; i++ )  {
    // 	if( document.siku2.elements[i].checked ) {
    // 	if( str2 != "" ) str2=str2+",";      
    // 	str2=str2+document.siku2.elements[i].value;
    // 	}
    // }

    // var str3="";
    // var COUNTsiku3= document.siku3.elements.length;
    // for( i=0; i<COUNTsiku3; i++ ) {
    // 	if( document.siku3.elements[i].checked ) {
    // 		if( str3 != "" ) str3=str3+",";      
    // 		str3=str3+document.siku3.elements[i].value;
    // 	}
    // }

    var str4="";
    var COUNTippan= document.ippan.elements.length;
    for( i=0; i<COUNTippan; i++ )  {
        if( document.ippan.elements[i].checked )  {
        str4=str4+document.ippan.elements[i].value; 
      }
    }

    var str5="";
    var COUNTnewArri= document.newArri.elements.length;
    for( i=0; i<COUNTnewArri; i++ )  {
      if( document.newArri.elements[i].checked ) {
      str5=str5+document.newArri.elements[i].value;
      }
    }

    var str6="";
    var COUNTJob= document.Job.elements.length;
    for( i=0; i<COUNTJob; i++ ) {
      if( document.Job.elements[i].checked ) {
      str6=str6+document.Job.elements[i].value;
      }
    }

    // var str7="";
    // var COUNTGyou= document.Gyou.elements.length;
    // for( i=0; i<COUNTGyou; i++ )  {
    // 	if( document.Gyou.elements[i].checked ) {  
    // 		str7=str7+document.Gyou.elements[i].value;
    // 	}
    // }

    // var str8="";
    // var COUNTjyouken= document.jyouken.elements.length;
    // for( i=0; i<COUNTjyouken; i++ ) {
    // 	if( document.jyouken.elements[i].checked ) {  
    // 		str8=str8+document.jyouken.elements[i].value;
    // 	}
    // }

    // var str9="";
    // var COUNTFree= document.Free.elements.length;
    // for( i=0; i<COUNTFree; i++ ) {
    // 	if( document.Free.elements[i].checked ) {
    // 	str9=str9+document.Free.elements[i].value;
    // 	}
    // }

    var str10="";
    var COUNTFree= document.FreeWord.elements.length;
    for( i=0; i<COUNTFree; i++ ) {
      if( document.FreeWord.elements[i].checked ) {
      str10=str10+document.FreeWord.elements[i].value;
      }
    }

    var target = document.getElementById("change_URL");
    target.href = "https://www.hellowork.mhlw.go.jp/kensaku/GECA110010.do?action=searchBtn&initDisp&screenId=GECA110010&searchClear=1&kjKbnRadioBtn=1" + str10 + "&siku1Hidden=" + str1 + "&siku2Hidden=&siku3Hidden=" + str4 + str5 + str6;
  }

  $('.searchbutton').on('click', function(){
    Search();
  })
});