$(document).ready(function () {
  let navPos = $('.site-title').outerHeight();
  $(window).resize(function() {
    navPos = $('.site-title').outerHeight();
  });
  $(window).on('scroll', function() {
    if ($(this).scrollTop() > navPos) {
      $('header').addClass('fixed');
    } else {
      $('header').removeClass('fixed');
    }
  });
});
