$(document).ready(function () {
	if($(window).width() > 768){
		var itemHeight = $('#support-item').height();
		$('#support-item-target').height(itemHeight);
	}
});

$(window).resize(function() {
  if($(window).width() > 768){
		var itemHeight = $('#support-item').height();
		$('#support-item-target').height(itemHeight);
	}
});
