$(document).ready(function () {
  var height = $('.top__img').outerHeight();
  var position = height - ( 331 + height / 12.83 )
  $('.text-position').css('top', position)
});

$(window).resize(function() {
  var height = $('.top__img').outerHeight();
  var position = height - ( 331 + height / 12.83 )
  $('.text-position').css('top', position)
});
