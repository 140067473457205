$(document).ready(function () {
  $('#company_image').on('change', function (e) {
    $('#preview').remove()
    var $image = $('#image');

    $image.cropper('destroy').removeAttr('src');
    let $cropperContainer = $('.cropper-container');
    $cropperContainer.remove();

    var reader = new FileReader();
    reader.onload = function (e) {
      $("#image").attr('src', e.target.result);
    }
    reader.readAsDataURL(e.target.files[0]);

    setTimeout(function () {
      $image.cropper({
        aspectRatio: 377 / 275,
        crop: function(e) {
          $('#dataX').val(Math.round(e.detail.x));
          $('#dataY').val(Math.round(e.detail.y));
          $('#dataWidth').val(Math.round(e.detail.width));
          $('#dataHeight').val(Math.round(e.detail.height));
        }
      });
    }, 500);
  });

  $('#job_image').on('change', function (e) {
    $('#preview').remove()
    var $image = $('#image');

    $image.cropper('destroy').removeAttr('src');
    let $cropperContainer = $('.cropper-container');
    $cropperContainer.remove();

    var reader = new FileReader();
    reader.onload = function (e) {
      $("#image").attr('src', e.target.result);
    }
    reader.readAsDataURL(e.target.files[0]);

    setTimeout(function () {
      $image.cropper({
        aspectRatio: 377 / 275,
        crop: function(e) {
          $('#dataX').val(Math.round(e.detail.x));
          $('#dataY').val(Math.round(e.detail.y));
          $('#dataWidth').val(Math.round(e.detail.width));
          $('#dataHeight').val(Math.round(e.detail.height));
        }
      });
    }, 500);
  });
});
