$(document).ready(function () {
  $('.hamburger__container').click(function() {
    $('body').toggleClass('is-noScroll');
    $('.hamburger__bar').toggleClass('close');
    $('.sp-menu').fadeToggle(300);
    // if($('#sp-header__container').hasClass('fixed')){
    //   $('.sp-header__container').removeClass('fixed-top')
    // } else {
    //   $('.sp-header__container').addClass('fixed-top')
    // }
    $('.sp-header__container').toggleClass('fixed-top');
  })
  $('.sp-menu__item').click(function(){
    $('.sp-menu').css('display', 'none');
    $('.hamburger__bar').toggleClass('close');
    $('body').toggleClass('is-noScroll');
  })
});
